<template>
  <div id="app">
    <h1>Vue.js and Express Example</h1>
    <GetRequest />
    <PostRequest />
  </div>
</template>

<script>
import GetRequest from './components/GetRequest.vue';
import PostRequest from './components/PostRequest.vue';

export default {
  components: {
    GetRequest,
    PostRequest
  }
};
</script>

<style>
/* Add some basic styling */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 40px;
}

button {
  margin-top: 10px;
}

input {
  margin-right: 10px;
}
</style>
