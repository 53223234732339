<template>
    <div>
      <h2>POST Request Example</h2>
      <form @submit.prevent="sendData">
        <input v-model="formData" placeholder="Enter some data" />
        <button type="submit">Send Data</button>
      </form>
      <p>{{ responseMessage }}</p>
    </div>
  </template>
  
  <script>

import axios from 'axios';

  export default {
    data() {
      return {
        formData: '',
        responseMessage: ''
      };
    },
    methods: {
      sendData() {
        axios.post('https://node.scms-app.com/post-endpoint', { data: this.formData })
          .then(response => {
            this.responseMessage = response.data.received;
          })
          .catch(error => {
            console.error('Error sending data:', error);
          });
      }
    }
  };
  </script>
  