<template>
    <div>
      <h2>GET Request Example</h2>
      <button @click="fetchData">Fetch Data</button>
      <p>{{ message }}</p>
    </div>
  </template>
  
  <script>

import axios from 'axios';


  export default {
    data() {
      return {
        message: ''
      };
    },
    methods: {
      fetchData() {
        axios.get('https://node.scms-app.com/new-endpoint')
          .then(response => {
            this.message = response.data;
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }
    }
  };
  </script>
  